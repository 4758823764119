import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="m129.92 597.333 362.027-362.026 362.026 362.026L491.947 959.36 129.92 597.333zm603.392 0L491.947 355.968 250.58 597.333 491.947 838.7l241.365-241.366zm93.27-414.464 6.826-100.394 85.12 5.717-17.11 253.141H662.614V256H778.24a383.403 383.403 0 0 0-286.293-128 383.787 383.787 0 0 0-332.672 192L85.46 277.333A469.077 469.077 0 0 1 491.947 42.667c128.554 0 248.064 52.138 334.634 140.202z" />
    </svg>
  )
}

export default SVG
