import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M117.298 128.826H879.78v172.66a28.771 28.771 0 0 1-57.542 0V186.4h-647.4v115.084a28.771 28.771 0 0 1-57.541 0v-172.66zm0 759.742H879.78V715.94a28.771 28.771 0 1 0-57.542 0v115.085h-647.4V715.94a28.771 28.771 0 1 0-57.541 0v172.627zM392.588 645.615h-54.701V434.804l-65.272 20.248v-44.461l114.094-40.86h5.88v275.884zm94.605-26.888c0-8.687 2.907-15.756 8.687-21.173a31.546 31.546 0 0 1 22.132-8.16c8.985 0 16.35 2.742 22.131 8.16a27.417 27.417 0 0 1 8.92 21.173c0 8.589-2.908 15.591-8.688 21.009-5.814 5.285-13.246 7.927-22.33 7.927a31.546 31.546 0 0 1-22.132-7.927 27.45 27.45 0 0 1-8.72-21.009zm0-154.954c0-8.72 2.907-15.756 8.687-21.207a31.546 31.546 0 0 1 22.132-8.126c8.985 0 16.35 2.709 22.131 8.126a27.417 27.417 0 0 1 8.92 21.207 27.417 27.417 0 0 1-8.688 21.008c-5.814 5.286-13.246 7.928-22.33 7.928a31.546 31.546 0 0 1-22.132-7.928 27.417 27.417 0 0 1-8.72-21.008zm237.27 181.842h-54.668V434.804l-65.272 20.248v-44.461l114.094-40.86h5.846v275.884z" />
    </svg>
  )
}

export default SVG
