import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M219.429 1024c-7.315 0-21.943 0-29.258-14.629L7.314 789.943S0 775.314 0 768V219.429c0-58.515 21.943-117.029 65.829-153.6 80.457-80.458 226.742-80.458 307.2 0 43.885 43.885 65.828 95.085 65.828 153.6V768c0 7.314 0 14.629-7.314 21.943L248.686 1009.37c0 7.315-14.629 14.629-29.257 14.629zM73.143 753.371l146.286 175.543 146.285-175.543V219.43c0-36.572-14.628-73.143-43.885-102.4-58.515-58.515-153.6-58.515-204.8 0-29.258 29.257-43.886 65.828-43.886 102.4V753.37z" />
      <path d="M402.286 365.714H36.57C14.63 365.714 0 351.086 0 329.143s14.629-36.572 36.571-36.572h365.715c21.943 0 36.571 14.629 36.571 36.572s-14.628 36.571-36.571 36.571zm365.714 512H621.714c-29.257 0-58.514-14.628-80.457-29.257C519.314 826.514 512 797.257 512 768V182.857c0-51.2 21.943-95.086 51.2-131.657 65.829-65.829 190.171-65.829 256 0 36.571 36.571 51.2 80.457 51.2 131.657V768c0 29.257-14.629 58.514-29.257 80.457-21.943 21.943-51.2 29.257-80.457 29.257H768zM694.857 73.143c-29.257 0-58.514 14.628-80.457 29.257-21.943 14.629-29.257 51.2-29.257 80.457V768c0 7.314 0 21.943 7.314 29.257 0 0 14.629 7.314 29.257 7.314H768c7.314 0 21.943 0 29.257-7.314 0 0 7.314-14.628 7.314-29.257V182.857c0-29.257-14.628-58.514-29.257-80.457-14.628-21.943-51.2-29.257-80.457-29.257z" />
      <path d="M694.857 1024c-21.943 0-36.571-14.629-36.571-36.571V841.143c0-21.943 14.628-36.572 36.571-36.572s36.572 14.629 36.572 36.572v146.286c0 21.942-14.629 36.571-36.572 36.571zM987.43 731.429c-21.943 0-36.572-14.629-36.572-36.572V402.286c0-7.315 0-21.943-7.314-29.257 0 0-14.629-7.315-29.257-7.315H548.57c-21.942 0-36.571-14.628-36.571-36.571s14.629-36.572 36.571-36.572h365.715c29.257 0 58.514 14.629 80.457 29.258 21.943 21.942 29.257 51.2 29.257 80.457v292.571c0 21.943-14.629 36.572-36.571 36.572z" />
    </svg>
  )
}

export default SVG
