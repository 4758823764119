import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M344.79 558.933h334.506c25.899 0 46.805 21.078 46.805 46.934 0 26.026-20.992 46.933-46.805 46.933H344.789a46.848 46.848 0 0 1-46.805-46.933c-.085-25.856 20.907-46.934 46.805-46.934zm0-187.861a46.933 46.933 0 1 1 0 93.867 46.933 46.933 0 0 1 0-93.867zm160.298 0h174.208a46.933 46.933 0 1 1 0 93.867H505.088a46.848 46.848 0 0 1-46.805-46.934c0-25.856 20.906-46.933 46.805-46.933zM287.189 62.592h449.664c110.294 0 199.808 89.813 199.808 200.448v497.92c0 110.635-89.514 200.405-199.808 200.405H287.147c-110.294 0-199.808-89.77-199.808-200.405V263.04c0-110.635 89.514-200.405 199.808-200.405zm0 100.267a100.01 100.01 0 0 0-99.882 100.138v497.92a100.01 100.01 0 0 0 99.882 100.224h449.664a100.01 100.01 0 0 0 99.883-100.224V263.04a100.01 100.01 0 0 0-99.883-100.224H287.147z" />
    </svg>
  )
}

export default SVG
