import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M512 426.667c93.867 0 170.667-76.8 170.667-170.667S605.867 85.333 512 85.333 341.333 162.133 341.333 256 418.133 426.667 512 426.667zm0-256c46.933 0 85.333 38.4 85.333 85.333s-38.4 85.333-85.333 85.333-85.333-38.4-85.333-85.333 38.4-85.333 85.333-85.333zm-256 384c-93.867 0-170.667 76.8-170.667 170.666C85.333 819.2 162.133 896 256 896s170.667-76.8 170.667-170.667c0-93.866-76.8-170.666-170.667-170.666zm0 256c-46.933 0-85.333-38.4-85.333-85.334C170.667 678.4 209.067 640 256 640s85.333 38.4 85.333 85.333c0 46.934-38.4 85.334-85.333 85.334zM512 473.6c-42.667 0-81.067 38.4-81.067 81.067s38.4 81.066 81.067 81.066c42.667 0 81.067-38.4 81.067-81.066S554.667 473.6 512 473.6zm256 81.067c-93.867 0-170.667 76.8-170.667 170.666C597.333 819.2 674.133 896 768 896s170.667-76.8 170.667-170.667c0-93.866-76.8-170.666-170.667-170.666zm0 256c-46.933 0-85.333-38.4-85.333-85.334C682.667 678.4 721.067 640 768 640s85.333 38.4 85.333 85.333c0 46.934-38.4 85.334-85.333 85.334z" />
    </svg>
  )
}

export default SVG
