import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M180.736 248.832 0 776.448h93.696l43.52-132.608h181.76l43.008 132.608h93.696l-179.2-527.616zm-16.128 311.552 63.488-204.8 62.976 204.8zm639.488-270.592c-32-25.6-82.944-39.936-151.552-39.936H530.176v526.592h86.016V588.8h51.2c121.088 0 185.088-58.88 185.088-169.984 1.024-58.624-15.616-102.144-48.384-129.024zm-38.656 130.56c0 34.048-10.752 79.104-102.4 79.104h-46.848v-161.28h51.2c66.816.256 98.048 26.368 98.048 82.176zm172.544-170.496H1024v526.592h-86.016z" />
    </svg>
  )
}

export default SVG
