import { memo, type SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1025 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M1024.127 337.281 686.72 0 512.064 174.528 337.344 0 0 337.281l174.592 174.655L0 686.72 337.345 1024l174.719-174.528L686.719 1024l337.408-337.281-174.655-174.783 174.655-174.655zM837.824 686.719 686.719 837.76 512.064 662.978 337.344 837.76 186.177 686.72l174.783-174.783-174.783-174.655L337.345 186.24l174.719 174.528L686.719 186.24 837.824 337.28 663.169 511.936 837.824 686.72z" />
    </svg>
  )
}

export default memo(SVG)
