import { memo, type SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M512 85.312C276.864 85.312 85.312 276.864 85.312 512S276.864 938.688 512 938.688 938.688 747.136 938.688 512 747.136 85.312 512 85.312zm0 85.376a340.48 340.48 0 0 1 338.432 298.624H721.024A214.208 214.208 0 0 0 512 298.688v-128zM512 384c71.168 0 128 56.832 128 128s-56.832 128-128 128-128-56.832-128-128 56.832-128 128-128zm209.024 170.688h129.408A339.84 339.84 0 0 1 753.6 753.6l-90.88-90.88a213.632 213.632 0 0 0 58.24-108.032z" />
    </svg>
  )
}

export default memo(SVG)
