import type { FC } from 'react'

import { Wrapper } from '../../salon/dashboard_intros/integrate_tab/content_card'

const ContentCard: FC = () => {
  return (
    <Wrapper>
      <div>integrate tab </div>
    </Wrapper>
  )
}

export default ContentCard
