import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1280 1024"
      {...props}
    >
      <path d="M14.936 389.8c-30.748-53.4-12.5-121.4 40.764-152.2L441.4 14.936c53.2-30.748 121.4-12.5 152.2 40.764l334 578.5c30.6 53.4 12.4 121.4-40.8 152.2L501 1009c-53.2 30.8-121.2 12.6-152-40.6L14.936 389.8zM392.2 319.2c-20.6-6-43.4 7.2-49.4 29.4l-55.2 206c-13.8 51.8 17 103.6 69 119.2 52 14 103.6-16.8 119.2-68.8l4.8-17.8c.2-.6.4-1.4.6-2.2l35.6 61.4-24.2 14c-13.4 7.6-17.8 24.6-10.2 38 7.8 13.4 24.8 17.8 38 10.2l96.4-55.6c13.4-7.8 18-24.8 10.2-38.2-7.6-13.2-24.6-17.8-38-10.2l-24 14-35.6-61.4 2.4.6 17.6 4.6c52 14 105.4-16.8 119.4-68.8 13.8-52-17-105.4-69-119.4l-207.6-55zm590.8 283L709.4 128.5c3.6-.34 7-2.3 10.6-2.3h448c61.8 0 112 51.94 112 112V912c0 61.8-50.2 112-112 112H720c-27.2 0-52.4-9.8-71.8-27.8l270.6-154.4c83.8-48.4 112.6-155.6 64.2-239.6zm84.8-127 96.2-100c28-29 26.6-76.8-4.4-103.8-28-23.6-69-19-93.8 6.4l-9.6 10.6-10-10.6c-25-25.4-66.4-30-93.4-6.4-31.4 27-33 74.8-5 103.8l97 100c6 6.4 16.4 6.4 23 0z" />
    </svg>
  )
}

export default SVG
