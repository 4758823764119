import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M819.103 271.553c-43.724 116.926-85.26 200.228-132.75 259.864-23.75 29.828-48.967 53.774-76.804 73.23-27.842 19.452-58.33 34.43-92.701 46.184-8.665 2.982-22.073 5.066-37.287 5.048-24.622.025-54.075-5.349-78.046-19.137-12.005-6.889-22.656-15.818-30.952-27.215-8.293-11.407-14.293-25.296-16.86-42.485a73.463 73.463 0 0 1-.81-10.863c.004-13.412 3.692-26.202 10.23-38.006 9.788-17.695 26.087-33.076 45.331-43.918 19.23-10.86 41.336-17.146 62.371-17.136 18.36.007 35.815 4.716 50.387 15.067 9.725 6.881 17.824 18.367 23.391 32.786 5.579 14.393 8.608 31.608 8.6 49.424.004 18.01-3.079 36.624-9.495 53.634-6.416 17.022-16.133 32.403-29.327 44.25-52.55 47.202-107.391 89.19-179.1 123.278-71.708 34.082-160.406 60.298-280.672 75.447l1.79 14.21c121.412-15.284 211.735-41.88 285.033-76.72 73.299-34.831 129.45-77.878 182.523-125.559 15.185-13.645 26.07-31.05 33.158-49.854 7.09-18.815 10.416-39.055 10.42-58.686-.008-19.428-3.255-38.264-9.568-54.594-6.323-16.306-15.725-30.223-28.453-39.292-17.35-12.331-37.899-17.723-58.68-17.712-31.819.039-64.422 12.406-89.492 32.581-12.525 10.104-23.17 22.203-30.738 35.851-7.566 13.638-12.027 28.873-12.023 44.949 0 4.275.315 8.607.967 12.972 2.867 19.327 9.8 35.56 19.448 48.804 14.483 19.893 34.856 32.944 56.345 41.007 21.519 8.067 44.254 11.235 64.23 11.242 16.512-.017 31.081-2.134 41.92-5.818 35.442-12.116 67.232-27.701 96.273-47.995 43.577-30.441 80.857-71.422 115.379-126.704 34.533-55.293 66.395-124.91 99.389-213.114l-13.427-5.02z" />
      <path d="m743.04 344.687 77.546-57.802 13.476 85.4a7.16 7.16 0 0 0 14.147-2.23l-15.325-97.109a7.14 7.14 0 0 0-4.371-5.514 7.143 7.143 0 0 0-6.982.892l-87.05 64.884a7.16 7.16 0 1 0 8.56 11.479z" />
    </svg>
  )
}

export default SVG
