import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M804.2 78.4H212.4c-81.7 0-148 66.2-148 148v591.8c0 81.7 66.2 148 148 148H827c18.9 0 34.1-15.3 34.1-34.1 0-18.9-15.3-34.1-34.1-34.1H212.4c-44 0-79.7-35.7-79.7-79.7V226.4c0-44 35.7-79.7 79.7-79.7h591.8c44 0 79.7 35.7 79.7 79.7v523.5c0 18.9 15.3 34.1 34.1 34.1 18.9 0 34.1-15.3 34.1-34.1V226.4c.1-81.7-66.2-148-147.9-148z" />
      <path d="M257.9 454h113.8c25.1 0 45.5-20.4 45.5-45.5V294.7c0-25.1-20.4-45.5-45.5-45.5H257.9c-25.1 0-45.5 20.4-45.5 45.5v113.8c0 25.1 20.4 45.5 45.5 45.5zm341.5-45.5c12.6 0 22.8-10.2 22.8-22.8v-68.3c0-12.6-10.2-22.8-22.8-22.8h-68.3c-12.6 0-22.8 10.2-22.8 22.8v68.3c0 12.6 10.2 22.8 22.8 22.8h68.3zm204.8-22.8v-68.3c0-12.6-10.2-22.8-22.8-22.8h-68.3c-12.6 0-22.8 10.2-22.8 22.8v68.3c0 12.6 10.2 22.8 22.8 22.8h68.3c12.6 0 22.8-10.2 22.8-22.8zm-10.5 182.1c0-18.9-15.3-34.1-34.1-34.1H257c-18.9 0-34.1 15.3-34.1 34.1 0 18.9 15.3 34.1 34.1 34.1h502.6c18.9.1 34.1-15.2 34.1-34.1zM257 693c-18.9 0-34.1 15.3-34.1 34.1 0 18.9 15.3 34.1 34.1 34.1h308.2c18.9 0 34.1-15.3 34.1-34.1 0-18.9-15.3-34.1-34.1-34.1H257z" />
    </svg>
  )
}

export default SVG
