import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M938.71 832a106.667 106.667 0 0 1-106.667 106.667h-304.64a42.667 42.667 0 0 1-30.294-72.534l250.454-256a43.392 43.392 0 0 1 30.293-12.8h54.187A106.667 106.667 0 0 1 938.709 704zM668.16 597.12 563.2 701.653a42.667 42.667 0 0 1-72.96-29.866V309.76a44.075 44.075 0 0 1 12.373-30.293l39.254-39.254a106.667 106.667 0 0 1 151.04 0l90.453 90.454a106.24 106.24 0 0 1 .427 151.04zM256 938.667a179.2 179.2 0 0 1-34.133-3.414 228.48 228.48 0 0 1-16.683-4.266 151.51 151.51 0 0 1-19.627-7.254v-.298l-.384.298a212.139 212.139 0 0 1-34.133-20.864l-.427-.426a116.01 116.01 0 0 1-15.36-13.654c-4.608-5.034-9.386-10.24-14.08-15.786a212.821 212.821 0 0 1-20.906-34.134c.256-.256.341-.341.298-.384h-.298a1.408 1.408 0 0 0-.427-.853 230.741 230.741 0 0 1-6.57-17.963l-.257-.896a231.552 231.552 0 0 1-4.266-16.64A180.181 180.181 0 0 1 85.333 768V192A100.992 100.992 0 0 1 192 85.333h128A100.992 100.992 0 0 1 426.667 192v576a170.027 170.027 0 0 1-48.64 119.04l-1.707 1.707a135.424 135.424 0 0 1-11.947 10.666 149.333 149.333 0 0 1-42.24 25.6c-4.778 2.134-9.557 3.883-14.08 5.547A166.016 166.016 0 0 1 256 938.667zM256 704a64 64 0 1 0 64 64 64 64 0 0 0-64-64z" />
    </svg>
  )
}

export default SVG
