import { memo, type SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M106.667 810.667a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm170.666 42.666a64 64 0 1 0 128 0 64 64 0 1 0-128 0zM448 704a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm170.667 42.667a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm170.666-85.334a64 64 0 1 0 128 0 64 64 0 1 0-128 0z" />
      <path d="M834.133 622.933 678.4 701.867l-177.067-44.8L330.667 806.4l-149.334-36.267L160 851.2l192 49.067 170.667-149.334 164.266 40.534 185.6-91.734zM106.667 426.667a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm170.666 42.666a64 64 0 1 0 128 0 64 64 0 1 0-128 0zM448 320a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm170.667 106.667a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm170.666-256a64 64 0 1 0 128 0 64 64 0 1 0-128 0z" />
      <path d="M817.067 147.2c-44.8 68.267-113.067 170.667-147.2 221.867-25.6-14.934-66.134-42.667-136.534-85.334L505.6 266.667 328.533 422.4 179.2 386.133l-21.333 83.2 192 49.067 164.266-142.933c55.467 34.133 123.734 76.8 138.667 87.466l10.667 10.667 19.2-2.133c23.466-2.134 23.466-2.134 202.666-275.2L817.067 147.2z" />
    </svg>
  )
}

export default memo(SVG)
