import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M640 170.667a85.333 85.333 0 1 0 170.667 0 85.333 85.333 0 1 0-170.667 0ZM673.152 468.01a85.632 85.632 0 0 0 91.648 35.414l141.483-35.37-20.608-82.774-141.483 35.37-58.837-88.191a85.76 85.76 0 0 0-54.272-36.438l-164.096-32.853a85.248 85.248 0 0 0-93.056 45.525l-70.742 141.483 76.331 38.187 70.741-141.526 83.926 16.811-217.003 361.685H128v85.334h189.184c29.781 0 57.813-15.872 73.173-41.43l81.835-136.362 220.544 44.117 77.483 232.49 80.896-27.007-77.44-232.448a85.632 85.632 0 0 0-64.256-56.747l-129.664-25.899 75.605-126.037 17.792 26.667z" />
    </svg>
  )
}

export default SVG
