import { memo, type SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M647.166 2.333 78.855 44.179c-45.806 3.96-61.78 33.826-61.78 69.631V734.96c0 27.886 9.966 51.745 33.893 83.625l133.596 173.19c21.948 27.886 41.881 33.86 83.796 31.88l659.958-39.833c55.807-3.96 71.78-29.866 71.78-73.659v-698.8c0-22.63-8.976-29.15-35.395-48.469a1594.684 1594.684 0 0 1-4.505-3.276L778.782 32.199C734.887.387 716.967-3.641 647.166 2.333zM283.31 199.927c-53.895 3.618-66.115 4.438-96.732-20.377l-77.823-61.712c-7.919-7.987-3.925-17.954 15.974-19.934l546.364-39.798c45.84-3.994 69.767 11.946 87.721 25.872l93.729 67.72c3.993 1.98 13.926 13.892 1.98 13.892l-564.25 33.86-6.963.477zM220.472 904.19V310.961c0-25.906 7.953-37.853 31.88-39.867l648.01-37.819c21.982-1.98 31.95 11.947 31.95 37.82v589.268c0 25.906-3.994 47.82-39.936 49.8l-620.09 35.839c-35.874 1.98-51.814-9.933-51.814-41.813zm612.171-561.416c3.96 17.92 0 35.84-17.988 37.887l-29.9 5.905v437.992c-25.94 13.927-49.834 21.88-69.767 21.88-31.914 0-39.936-9.967-63.829-39.8l-195.444-306.58V796.67l61.849 13.96s0 35.84-49.902 35.84l-137.556 7.953c-3.959-7.988 0-27.887 13.995-31.846l35.84-9.933V420.459l-49.835-4.027c-3.959-17.92 5.974-43.793 33.928-45.772l147.556-9.967L714.988 671.3V396.532l-51.882-5.939c-3.96-21.947 11.946-37.853 31.914-39.833l137.623-7.987z" />
    </svg>
  )
}

export default memo(SVG)
