import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M224 96v175.008l12 9.984L364.992 384h-97.984l-120-90.016-38.016 52.032 136 101.984h114.016L244 544H96v64h172l8-7.008L320.992 564c-.256 4-.992 7.872-.992 12 0 5.632.512 11.264.992 16.992l-120 120L192 723.008V928h64V749.984L338.016 668c8.736 24.128 19.84 48 33.984 70.016C404.256 788.096 451.488 832 512 832c60.48 0 107.744-43.872 140-94.016A351.36 351.36 0 0 0 686.016 668L768 750.016V928h64V723.008l-8.992-10.016-120-120c.48-5.76.992-11.36.992-16.992 0-3.872-.736-7.264-.992-11.008l44.992 36 8.992 7.008H928v-64H779.008l-120-96h120l136-102.016-38.016-51.968-120 89.984h-97.984L788 280.992l12-9.952V96h-64v144.992l-100 80c-4.992-19.84-15.008-36.992-28-52V160h-64v68.992c-10.24-2.624-20.992-4.992-32-4.992s-21.76 2.368-32 4.992V160h-64v108.992c-12.992 15.008-23.008 32.128-28 52l-100-80V96zm288 192c35.744 0 64 28.256 64 64s-28.256 64-64 64-64-28.256-64-64 28.256-64 64-64zm0 192c39.36 0 72.512 9.504 94.016 24.992C627.456 520.512 640 540.512 640 576c0 35.52-16.384 88.128-42.016 128-25.6 39.872-58.24 64-85.984 64-27.744 0-60.384-24.128-86.016-64C400.384 664.128 384 611.52 384 576c0-35.52 12.48-55.488 34.016-71.008C439.456 489.504 472.64 480 512 480z" />
    </svg>
  )
}

export default SVG
