import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 256 256" {...props}>
      <path d="M224 128a96 96 0 1 1-96-96 96 96 0 0 1 96 96Z" opacity={0.2} />
      <path d="M184 128a246.64 246.64 0 0 1-18.54 94.24 8 8 0 0 1-7.4 5 8.19 8.19 0 0 1-3-.6 8 8 0 0 1-4.36-10.45A230.67 230.67 0 0 0 168 128a8 8 0 0 1 16 0Zm-56-40a40.06 40.06 0 0 1 29.81 13.33 8 8 0 1 0 11.92-10.67A56 56 0 0 0 72 128a136.06 136.06 0 0 1-17 65.85 8 8 0 1 0 14 7.76A152.14 152.14 0 0 0 88 128a40 40 0 0 1 40-40Zm0-64a103.75 103.75 0 0 0-34.67 5.92A8 8 0 0 0 98.67 45 88.05 88.05 0 0 1 216 128a281.31 281.31 0 0 1-6.94 62.23 8 8 0 0 0 6 9.57 7.77 7.77 0 0 0 1.78.2 8 8 0 0 0 7.8-6.23A298.11 298.11 0 0 0 232 128 104.11 104.11 0 0 0 128 24ZM69.31 62.42a8 8 0 1 0-10.67-11.93A104.16 104.16 0 0 0 24 128a87.29 87.29 0 0 1-8 36.66 8 8 0 0 0 14.54 6.68A103.17 103.17 0 0 0 40 128a88.13 88.13 0 0 1 29.31-65.58Zm44.58 138.32a8 8 0 0 0-10.6 3.93c-1.93 4.2-4.05 8.39-6.3 12.44a8 8 0 0 0 3.12 10.89 7.88 7.88 0 0 0 3.87 1 8 8 0 0 0 7-4.12c2.44-4.41 4.74-9 6.84-13.52a8 8 0 0 0-3.93-10.62ZM128 120a8 8 0 0 0-8 8 185.07 185.07 0 0 1-5.79 46 8 8 0 0 0 5.75 9.74 8.12 8.12 0 0 0 2 .25 8 8 0 0 0 7.74-6 200.68 200.68 0 0 0 6.3-50 8 8 0 0 0-8-7.99Z" />
    </svg>
  )
}

export default SVG
