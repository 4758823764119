import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M806.176 170.08c-125.6-125.136-292.368-118.048-420.064 9.28L121.6 442.928l57.04 56.848c271.888-270.928 313.92-335.952 419.76-339.28 142.352-5.12 317.872 195.44 141.392 371.344-291.808 290.768-313.456 329.44-391.28 331.712-35.232 1.184-68.416-12.752-95.6-39.84-59.616-59.456-53.312-142.624 16.176-211.888 0 0 124.064-123.92 193.376-193.024 60.64-60.416 154.848 32.56 93.792 93.44L373.712 694.16l57.04 56.848c191.392-190.736 229.232-211.104 225.76-289.28-5.376-121.088-156.32-194.128-251.088-99.776-69.344 69.136-193.408 193.056-193.408 193.056C38.592 727.888 175.184 944 344.928 944c1.952 0 3.952-.032 5.952-.08 60.336-1.76 119.28-29.648 170.416-80.672l275.52-274.56C920.944 465.04 931.184 294.64 806.16 170.08z" />
    </svg>
  )
}

export default SVG
