import styled, { css } from '~/css'

export const Wrapper = styled.div`
  ${css.row()};
  width: 100%;
  height: 100%;
  position: relative;
  margin-left: 46px;
`
export const holder = 1
