import { COLOR_NAME } from '~/const/colors'

import useTwBelt from '~/hooks/useTwBelt'

export { cn } from '~/css'

export default () => {
  const { cn, fg, bg, fill, global, br, shadow, rainbow } = useTwBelt()

  return {
    wrapper: cn('row w-full h-44 px-4 py-4 mt-20'),
    ogPanel: 'w-1/2 pl-2.5 relative',
    twPanel: 'w-1/2 pl-14 relative',
    logo: cn('size-9 rounded mb-4 mt-2', global('gradient-orange')),

    title: cn('text-xs mb-1.5 mt-2.5', fg('text.title')),
    desc: cn('text-xs', fg('text.digest')),
    //
    linkDesc: cn('text-xs mt-1.5', fg('text.digest')),
    iconBox: 'absolute left-2.5',
    icon: cn('size-3.5 mr-1 opacity-65', fill('text.digest')),
    //
    bar: cn('w-10 h-1.5 opacity-15 rounded-md absolute', bg('text.digest')),
    editBox: cn(
      'align-both size-9 rounded-2xl border',
      'absolute top-48 left-48',
      br('divider'),
      shadow('sm'),
      global('gradient-orange'),
    ),
    editIcon: cn('size-4 opacity-65', rainbow(COLOR_NAME.BROWN, 'fill')),
  }
}
