import { memo, type SVGProps } from 'react'

const Star = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M260.352 431.659c-96.683 0-175.019-77.952-175.019-172.374 0-94.378 78.336-173.952 175.019-173.952 96.64 0 174.976 77.867 174.976 173.995 0 96.085-80 172.288-174.976 172.288zm0-246.912c-41.685 0-75.008 33.152-75.008 74.581 0 41.387 33.323 74.539 75.008 74.539 41.643 0 74.965-33.11 74.965-74.539 0-41.43-34.986-74.581-74.965-74.581zm505.003 748.97c-96.683 0-175.019-77.909-175.019-173.994 0-96.128 78.336-172.331 174.976-172.331 96.683 0 175.019 77.867 175.019 173.995 0 96.085-80 172.33-175.019 172.33zm0-246.912c-41.686 0-75.008 33.152-75.008 74.582 0 41.386 33.28 74.538 75.008 74.538 41.642 0 74.965-33.109 74.965-74.538 0-41.43-34.987-74.582-75.008-74.582z" />
      <path d="M260.352 938.667a48.981 48.981 0 0 1-50.005-49.707V386.901c0-28.16 21.674-49.706 50.005-49.706 28.33 0 49.963 21.546 49.963 49.706V888.96c0 26.496-23.296 49.707-49.963 49.707zm505.003-251.862a48.981 48.981 0 0 1-50.006-49.706V358.74c0-28.16-21.674-49.706-50.005-49.706H383.659a48.981 48.981 0 0 1-50.006-49.707c0-28.16 21.675-49.707 50.006-49.707h281.685a148.907 148.907 0 0 1 149.973 149.12v280.022c0 26.496-23.338 48.042-50.005 48.042z" />
    </svg>
  )
}

export default memo(Star)
