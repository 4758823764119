import { memo, type SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M512 85.333a298.667 298.667 0 0 0-288.512 375.979 42.667 42.667 0 0 0 82.432-22.101A213.333 213.333 0 1 1 725.333 384a218.197 218.197 0 0 1-30.08 109.525L543.147 810.667a85.547 85.547 0 0 1-73.814 42.666A86.485 86.485 0 0 1 384 768a84.992 84.992 0 0 1 11.477-42.624 42.667 42.667 0 0 0-73.941-42.752 170.624 170.624 0 1 0 297.088 167.85l152.021-317.14A298.667 298.667 0 0 0 512 85.333zm0 256A42.667 42.667 0 0 1 554.667 384 42.667 42.667 0 0 0 640 384a128 128 0 1 0-214.101 94.72c.426.427 6.912 7.253 8.277 9.216a42.155 42.155 0 0 1 0 47.744 42.667 42.667 0 1 0 70.315 48.213 127.275 127.275 0 0 0-.214-144.554 303.957 303.957 0 0 0-20.906-23.766 45.013 45.013 0 0 1-6.827-7.722A42.667 42.667 0 0 1 512 341.333z" />
    </svg>
  )
}

export default memo(SVG)
