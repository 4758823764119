import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      className="icon"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M899.123 749.305c-25.866-91.284-109.595-158.203-209.144-158.203-6.724 0-13.21 1.067-19.776 1.66v77.441L530.352 530.352 670.203 390.54v82.898c6.526-.593 13.091-.989 19.776-.989 120.155 0 217.529 97.374 217.529 217.53 0 20.605-3.046 40.46-8.385 59.326ZM689.939 314.246h.04c3.678 0 6.92 1.266 9.887 3.006-3.243-1.305-6.565-2.136-9.927-3.006Zm-92.035 395.508H254.92c-10.877 0-19.775-8.899-19.775-19.775 0-10.877 8.898-19.776 19.775-19.776h303.434l39.55 39.55ZM254.92 551.55h199.573c3.6 12.972 10.165 24.956 19.934 34.765l4.785 4.786H254.92c-10.877 0-19.775-8.9-19.775-19.776s8.898-19.775 19.775-19.775Zm-19.775-217.53c0-10.916 8.859-19.775 19.775-19.775h395.429c-13.487 3.48-25.946 10.244-36.07 20.369l-19.183 19.182H254.92c-10.916 0-19.775-8.86-19.775-19.776ZM254.92 472.45c-10.877 0-19.775-8.899-19.775-19.775 0-10.877 8.898-19.776 19.775-19.776h261.075l-39.551 39.551H254.92Zm533.935-276.855c0-21.753-17.797-39.551-39.55-39.551H195.594c-21.753 0-39.551 17.798-39.551 39.55v632.813c0 21.753 17.798 39.551 39.55 39.551h553.712c21.753 0 39.55-17.798 39.55-39.55V713.115c15.623 16.136 27.884 35.556 34.133 57.704 1.186 4.193 3.599 7.634 5.418 11.47v46.116c0 43.506-35.595 79.102-79.101 79.102H195.594c-43.506 0-79.102-35.596-79.102-79.102V195.594c0-43.506 35.596-79.102 79.102-79.102h553.71c43.507 0 79.102 35.596 79.102 79.102v232.519c-12.656-6.763-25.866-12.577-39.55-17.442V195.594Z" />
    </svg>
  )
}

export default SVG
