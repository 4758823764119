import type { SVGProps } from 'react'

const SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={200}
      height={200}
      {...props}
    >
      <path d="M270.792 966.105c-117.454 0-213.074-95.573-213.074-213.078v-85.193a42.62 42.62 0 1 1 85.24 0v85.193a127.98 127.98 0 0 0 127.834 127.835H753.75c70.411 0 127.834-57.321 127.834-127.835v-85.193a42.595 42.595 0 1 1 85.189 0v85.193c0 117.505-95.569 213.074-213.023 213.074zm198.86-326.697v-431.16L358.843 327.543a42.623 42.623 0 0 1-62.485-57.986l184.645-198.86c16.26-17.334 46.327-17.334 62.485 0l184.694 198.86a42.642 42.642 0 0 1-62.536 57.986L554.895 208.248v431.16a42.62 42.62 0 0 1-85.24 0z" />
    </svg>
  )
}

export default SVG
